@import "variables";

#residential {
  .col.content {
    display: flex;
    align-items: flex-start;

    h1,
    h2,
    h3,
    h4,
    h5 {
      text-align: left;
    }

    h1,
    h4 {
      margin: 0;
    }
  }

  @media (max-width: 1000px) {
    .col {
      &.content > .trans {
        margin-top: 0;
      }

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding: $gutter/2 0;
      }
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
}
