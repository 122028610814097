@import "variables.scss";

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  user-select: none;
}

body {
  height: calc(100vh);
  background-color: $white;
  color: $accent-one;
  font-family: $body-font;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

button,
a {
  color: $accent-one;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $accent-two;
  }

  &.btn {
    background: $accent-one;
    border-radius: 8px;
    border: none;
    color: $white;
    display: inline-block;
    font-weight: 300;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
    padding: 0 $gutter * 1.25;

    &.white {
      background: $white;
      color: $accent-one;
    }

    &.hollow {
      background: transparent;
      border: 1px solid $accent-one;
      color: $accent-one;
    }

    &.phone-btn {
      border-radius: 21px;
      font-weight: 500;

      &:active,
      &:focus,
      &:hover {
        background-color: $blue;
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font;
  font-weight: 700;
  text-align: center;
  margin: $gutter 0;
}

h1 {
  font-size: 2.75rem;
}

h2 {
  font-size: 2.25rem;
  font-weight: 500;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

h5 {
  font-size: 1.1rem;

  &.trans {
    color: $gray;
  }
}

p {
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 1rem;
}

strong {
  font-weight: 700;
}

main {
  display: flex;
  flex-direction: column;

  article {
    display: flex;
    overflow: hidden;
    padding: $gutter $gutter * 2;

    @media (max-width: 600px) {
      padding: $gutter;
    }
  }
}

.row {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .col {
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
    flex-grow: 2;
    padding: $gutter;
  }
}

.social-links {
  display: flex;
  justify-content: space-between;
  margin-bottom: $gutter;

  img {
    width: 2rem;
    height: 2rem;
    margin: 0 1rem;
  }
}

header {
  align-items: center;
  background: $accent-one;
  display: flex;
  justify-content: space-between;
  padding: $gutter;
  width: 100vw;

  img.logo {
    width: 200px;
  }

  .nav-social-wrapper .social-links {
    justify-content: flex-end;
  }

  a {
    color: $white;

    &:hover,
    &:focus {
      color: $blue;
    }
  }

  .btn {
    background: $white;
    color: $accent-one;

    &:focus,
    &:hover {
      color: $white;
    }
  }

  @media (max-width: 600px) {
    padding: $gutter/2;
  }
}

nav {
  display: flex;
  flex-grow: 2;
  justify-content: flex-end;

  ul {
    display: flex;
    padding: 0;

    li {
      align-items: center;
      display: flex;
      text-transform: uppercase;
      margin: 0 calc($gutter/3);

      a {
        font-size: 0.9rem;
        padding: 0 5px;

        &.active {
          color: $gray;
        }
      }
    }
  }
}

footer {
  align-items: center;
  background: $accent-one;
  color: $gray;
  display: flex;
  flex-direction: column;
  padding: $gutter;
  position: relative;
  justify-content: center;

  nav {
    margin-top: $gutter/2;

    ul li {
      &:last-of-type {
        display: none;
      }
      a {
        color: $white;
        font-size: 1rem;
        text-transform: capitalize;

        &:hover,
        &:active,
        &:focus {
          color: $blue;
        }
      }
    }

    @media (max-width: 600px) {
      ul {
        flex-direction: column;

        li {
          justify-content: center;
          margin: 4px 0;
        }
      }
    }
  }

  .phone-btn {
    background: $accent-two;
    margin: $gutter/2 0 $gutter;
  }

  .copyright {
    font-size: 0.8rem;
    margin-top: $gutter/2;
    text-align: center;

    a {
      color: $white;
    }
  }

  .social-links {
    align-items: center;
  }

  img.logo {
    margin: 0.25rem 0;
    width: 200px;
  }
}

@media (max-width: 1000px) {
  nav ul li {
    margin: 0 calc($gutter/4);
  }

  button,
  a {
    &.btn {
      padding: 0 $gutter/2;
    }
  }
}
@media (max-width: 950px) {
  h1 {
    font-size: 2.25rem;
  }
  h2 {
    font-size: 1.75rem;
  }
  h3 {
    font-size: 1.25rem;
  }
  h4,
  h5 {
    font-size: 1rem;
  }

  button,
  a {
    &.btn {
      padding: 0 $gutter/2;
    }
  }

  .nav-social-wrapper {
    display: none;
  }
}
