@import "variables";

#testimonials {
  background-color: $light-gray;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 0;
    }

    p {
      padding: 0 $gutter;
    }

    .testimony-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin: $gutter 0;
      max-width: 100vw;
    }

    .testimony {
      align-items: flex-start;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      margin: $gutter/2;
      max-width: calc(20vw - 1.2rem);

      p {
        margin-top: 0;

        &.company {
          margin: 0;
        }
      }

      .photo img {
        margin-bottom: 1rem;
      }
    }
  }

  @media (max-width: 900px) {
    .content .testimony {
      max-width: calc(28vw - 1.2rem);
    }
  }

  @media (max-width: 700px) {
    .content .testimony {
      max-width: 100%;
    }
  }
}
