@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;500&family=Open+Sans:wght@100;300;500;700&display=swap");

$body-font: "Roboto", sans-serif;
$heading-font: "Open Sans", sans-serif;

$gutter: 2rem;

$black: #000;
$white: #fff;

$border-gray: #bfbfbf;

// Layout Styles
$dark-gray: #1a1a1a;
$gray: #999999;
$medium-gray: #e6e6e6;
$light-gray: #f2f2f2;

$light-blue: #f5f9fd;
$medium-blue: #c7e1ff;

$border-blue: #72b4ff;

// Accent Colors
$accent-one: #202020;
$accent-two: darken($accent-one, 10%);
$blue: #0076ff;
$red: #e74c3c;
$green: #27ae60;

// Gradient Styles
$linear-gradient: linear-gradient(0deg, $accent-one 0%, $accent-two 100%);
$reverse-linear-gradient: linear-gradient(
  0deg,
  $accent-two 0%,
  $accent-one 100%
);
$radial-gradient: radial-gradient(circle, $accent-one 0%, $accent-two 60%);
$box-shadow-color: rgba(153, 153, 153, 0.35);
