@import "variables.scss";

#team .row {
  flex-direction: row;

  .col {
    align-items: center;
    margin: $gutter;
    padding: $gutter;
  }
}
