@import "variables.scss";

#steps {
  flex-direction: row;

  li {
    list-style-type: none;
    margin-bottom: $gutter;
  }

  h4 {
    background: $light-gray;
    border-radius: 8px;
    display: inline-block;
    padding: $gutter/5 $gutter/2;
    margin: 0;
  }
}
