@import "variables.scss";

#contact {
  background: $light-gray;
  justify-content: center;

  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: $gutter * 3;
    text-align: center;
    width: 70vw;
  }

  form#email {
    margin-right: 0;
    max-width: 600px;
    width: 100%;
  }

  img {
    margin-top: 4rem;
  }
}
