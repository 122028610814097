@import "variables.scss";

.toggle {
  align-items: center;
  background: $medium-gray;
  border-radius: 16px;
  color: $gray;
  display: flex;
  overflow-x: scroll;
  padding: $gutter/4;
  margin-top: $gutter;
  max-width: 100vw;

  span {
    border-radius: 16px;
    cursor: pointer;
    padding: $gutter/2 $gutter;

    &:hover {
      color: $accent-one;
    }

    &.active {
      background: $white;
      border-radius: 0 2px 4px hsla(212, 30%, 58%, 0.32);
      color: $accent-one;
    }
  }

  @media (max-width: 810px) {
    border-radius: 0;
    max-width: 100vw;
  }
}
