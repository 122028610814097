@import "variables";

#commercial {
  background-color: $light-gray;

  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: left;
  }

  h1 {
    margin: 0;
  }

  p {
    max-width: 800px;
  }

  .btn {
    margin-top: $gutter;
    align-self: flex-start;
  }

  @media (max-width: 1000px) {
    .col {
      & > .trans {
        margin-top: 0;
      }

      &:first-child {
        padding: $gutter/2 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;

    .col:last-child {
      padding: $gutter 0 0;
    }
  }
}
