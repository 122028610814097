@import "variables";

#process {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: $gutter * 2;

  .content {
    align-items: stretch;
    background: linear-gradient(
        90deg,
        rgba(32, 32, 32, 0.9) 0%,
        rgba(32, 32, 32, 0.9) 100%
      ),
      url("../assets/portfolio/bathroom-1.jpg");
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    color: $white;
    display: flex;
    flex-direction: row;
    padding: $gutter * 2;
    text-align: left;

    h1,
    h2,
    h3,
    h4,
    h5 {
      text-align: left;
    }

    h1 {
      margin: 0;
    }

    .btn {
      margin-top: $gutter;
    }

    .col {
      align-items: stretch;
      width: 50%;

      &#steps h4 {
        color: $accent-one;
      }

      &:first-child {
        border-right: 1px solid $border-gray;
        justify-content: center;
      }
    }
  }

  @media (max-width: 900px) {
    .content {
      flex-direction: column;

      .col {
        padding: 0;
        width: 100%;

        &:first-child {
          border-right: none;
        }

        ul {
          padding: 0;
        }
      }
    }
  }

  @media (max-width: 600px) {
    padding: 0;

    .content {
      border-radius: 0;
      padding: $gutter;
    }
  }
}
