@import "variables";

#welcome {
  align-items: center;
  background: linear-gradient(
      90deg,
      rgba(32, 32, 32, 0.4) 0%,
      rgba(32, 32, 32, 0.4) 100%
    ),
    url("../assets/portfolio/exterior-1.jpg");
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  height: 90vh;

  .hero-content {
    color: $white;
    display: flex;
    flex-direction: column;
    position: absolute;
    text-align: center;
  }

  @media (max-width: 600px) {
    height: 60vh;

    .hero-content {
      padding: $gutter/2;
    }
  }

  .col {
    align-items: center;
  }

  .content {
    display: flex;
    justify-content: center;
  }

  .actions {
    display: flex;
    justify-content: center;
    width: 100%;

    a {
      margin: 0 $gutter/4;
    }
  }

  @media (max-width: 950px) {
    .col {
      padding: 0;

      &:first-of-type {
        padding-right: $gutter;
      }
    }
  }

  @media (max-width: 710px) {
    flex-direction: column;

    h1,
    h3 {
      text-align: center;
    }

    h1 {
      margin: 0;
    }

    .actions {
      justify-content: center;
      margin-bottom: 2rem;
    }

    .col:first-of-type {
      padding: 0;
    }
  }
}
