@import "variables.scss";

#packages.row {
  flex-direction: row;
  margin-top: 1rem;
  padding: $gutter;

  .col {
    align-items: center;
    background: $white;
    border-radius: 12px;
    box-shadow: 0 10px 20px $box-shadow-color;
    display: flex;
    flex-direction: column;
    margin: 0 $gutter;
    padding: $gutter;

    &.highlight {
      background: transparent;
      box-shadow: none;
    }

    a.btn {
      background: $accent-two;
      color: $white;
      margin-top: $gutter/2;

      &:hover {
        background: $accent-one;
        color: $black;
      }
    }

    h3 {
      // color: $white;
      font-size: 1.5rem;
      font-weight: 400;
    }

    h4 {
      border-bottom: 1px solid $white;
      font-family: $heading-font;
      font-size: 1.25rem;
      padding: 0 $gutter $gutter/2;
      margin: 0;
      text-transform: uppercase;
      white-space: nowrap;
      width: 100%;
    }

    ul {
      flex-grow: 2;
      list-style-type: none;
      margin: 0;
      padding: $gutter/2 0;
      width: 100%;

      li {
        border-bottom: 1px solid $border-gray;
        line-height: 3.5rem;
        text-align: center;

        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }

  @media (max-width: 1100px) {
    .col {
      margin: 0 $gutter/2;
      padding: $gutter $gutter/2;
    }
  }

  @media (max-width: 1000px) {
    padding: $gutter/2;
    padding-left: 0;

    form#email {
      margin-right: 0;
    }

    .col {
      &:first-of-type {
        margin-left: 0;
      }

      ul li {
        padding: $gutter/2 0;
        line-height: 1;
      }
    }
  }

  @media (max-width: 840px) {
    padding: $gutter/2 0;

    .col:last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: 650px) {
    flex-direction: column;

    .col {
      margin: 0 0 $gutter;
    }
  }
}
