@import "variables.scss";

#smart-tint {
  justify-content: center;

  .content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: $gutter * 3;
    text-align: center;
    width: 70vw;
  }

  img {
    margin-top: 2rem;
  }

  @media (max-width: 600px) {
    .content {
      padding: 0;
      width: 100vw;
    }
  }
}
