@import "variables.scss";

form#email {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 35%;

  h2 {
    color: $green;
    font-size: 1rem;
    margin-top: -1rem;
    text-align: center;
  }

  h4 {
    text-transform: uppercase;
  }

  .col:first-of-type {
    margin-right: $gutter;
  }

  sup {
    color: $accent-one;
  }

  label {
    color: $accent-one;
  }

  input,
  textarea {
    background: $white;
    border: none;
    border: 1px solid $accent-one;
    color: $accent-one;
    display: flex;
    font-family: $body-font;
    font-size: 1rem;
    margin-bottom: $gutter/2;
    min-width: 200px;
    padding: 0.5rem;

    &::placeholder {
      color: $accent-one;
      font-size: 0.9rem;
      font-weight: 300;
    }

    &:focus,
    &:active {
      border-bottom-color: $black;
      outline: none;
    }
  }

  .checkbox {
    display: flex;

    input {
      min-width: auto;
      margin-right: 8px;
    }
  }

  button {
    background: $accent-one;
    color: $white;
    margin: 0 auto;
    min-width: 150px;
    width: 80%;

    &:hover,
    &:focus,
    &:active {
      background: $accent-two;
      color: $white;
    }
  }
}
