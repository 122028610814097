@import "variables";

#services {
  background: $light-gray;
  flex-direction: column;
  text-align: center;

  h1 {
    margin: $gutter/2;
  }

  h5 {
    margin-bottom: $gutter/4;
  }

  p {
    margin: 0 auto;
    max-width: 800px;
  }

  @media (max-width: 840px) {
    & > .row {
      flex-direction: column;
    }

    form#email {
      margin: 0 auto;
      width: 80%;
    }
  }
}
